import {
  DELETE_GROUP,
  GET_GROUPS_BY_MEMBER,
  GROUP_INFO,
  LEAVE_GROUP,
} from "../constants/api";
import { Avatar, Button, Typography } from "@mui/material";
import axios from "../MyAxios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { trunc } from "@helpers";

function GroupCard({ group, setGroups, isPending }) {
  const navigation = useNavigate();
  const { userRecord } = useSelector((state) => state.user);

  const onLeaveGroup = (e, id) => {
    e.stopPropagation();
    axios
      .post(LEAVE_GROUP, { groupId: id })
      .then(() => {
        toast.success("Left Group");
        setGroups((groups) => groups.filter((group) => group.id !== id));
      })
      .catch(() => {});
  };

  const onDeleteGroup = (e, id) => {
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete this group?") === false)
      return;
    axios
      .post(DELETE_GROUP, { groupId: id })
      .then(() => {
        toast.success("Deleted Group");
        setGroups((groups) => groups.filter((group) => group.id !== id));
      })
      .catch(() => {});
  };
  const groupInfo = (id) => {
    navigation(`${GROUP_INFO}/${id}`);
  };
  
  const groupDisabled = group?.disabledAt > 0;
  const groupCommunityDisabled = group?.community?.disabledAt > 0;

  return (
    <div
      key={group?.id}
      onClick={() => groupInfo(group?.id)}
      className=" flex w-full flex-row cursor-pointer items-center border-b-2 p-2"
    >
      <div>
        <Avatar
          className=" h-10 w-10 rounded-full"
          src={group?.icon?.avatarUrl}
        />
      </div>
      <div className="flex w-full justify-between p-2 pl-4">
        <div>
          <Typography color={"text.dark"} className={clsx("inline text-xs font-bold")}>
            {group.name}{' '}
            <Typography className="text-xs inline">
              ({trunc(group?.community?.name, 30)})
            </Typography>
          </Typography>
          <Typography className={clsx(" leading-none")}>
            <small>{group.name}</small>
          </Typography>
        </div>
        {isPending && (
          <Typography color="primary" className="text-sm px-2 py-3">
            Pending
          </Typography>
        )}
        {(!isPending && group.disabledAt == 0 && group.community?.disabledAt == 0) &&
          (userRecord.id === group.admin ? (
            <Button
              disabled={groupDisabled || groupCommunityDisabled}
              color="error"
              className="text-sm"
              sx={{ textTransform: "none" }}
              onClick={(e) => onDeleteGroup(e, group.id)}
            >
              Delete
            </Button>
          ) : (
            <Button
              color="error"
              className="text-sm"
              sx={{ textTransform: "none" }}
              onClick={(e) => onLeaveGroup(e, group.id)}
            >
              Leave
            </Button>
          ))}
      </div>
    </div>
  );
}

function MyGroups({ noPending = false, get_url }) {
  const [groups, setGroups] = useState([]);
  const [pendingGroups, setPendingGroups] = useState([]);
  useEffect(() => {
    const getGroups = async () => {
      try {
        Promise.all([
          axios.get(get_url || GET_GROUPS_BY_MEMBER),
          noPending ? [] : axios.get("/groups/pending")
        ])
          .then(responses => {
            setGroups(responses[0].data);
            setPendingGroups(responses[1].data);
          })
      } catch (error) {}
    };
    getGroups();
  }, [get_url]);

  return (
    <div className=" flex flex-col items-center p-2">
      {groups?.map((group) => (
        <GroupCard group={group} setGroups={setGroups} />
      ))}
      {pendingGroups?.map((group) => (
        <GroupCard group={group} setGroups={setPendingGroups} isPending />
      ))}
    </div>
  );
}

export default MyGroups;
